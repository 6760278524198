// product stock report api
export const reportHeadingList = '/seeds/config/report-heading/detail'

export const productStockReport = '/seeds/stock-manage/sd-stock/report'

export const varietiesNameWise = 'seeds/config/price-infos/varieties-name-wise'
export const stockManageStore = '/seeds/stock-manage/sd-stock/store'
export const stockManageList = '/seeds/stock-manage/sd-stock/list'
export const stockManageUpdate = '/seeds/stock-manage/sd-stock/update'
export const stockManageToggleStatus = '/seeds/stock-manage/sd-stock/toggle-status'
export const varietyDetails = 'seeds/stock-manage/sd-stock/varietyDetails'

export const applicationRequestList = '/seeds/seeds-manage/seeds-allocation-app/list'
export const applicationRequestPendingList = '/seeds/seeds-manage/seeds-allocation-app/listForPending'
export const applicationRequestShow = '/seeds/seeds-manage/seeds-allocation-app/show'
export const applicationRequestStatusChange = '/seeds/seeds-manage/seeds-allocation-app/approved'
export const applicationRequestRejectStatus = '/seeds/seeds-manage/seeds-allocation-app/reject'

// Application Allocation api
export const applicationAllocationList = 'seeds/seeds-manage/seeds-allocation-app/list'
export const applicationAllocationStore = 'seeds/seeds-manage/seeds-allocation-app/store'
export const applicationAllocationUpdate = 'seeds/seeds-manage/seeds-allocation-app/update'
export const applicationRequestUpdate = 'seeds/seeds-manage/seeds-allocation-app/applicationUpdate'
export const applicationAllocationToggleStatus = 'seeds/seeds-manage/seeds-allocation-app/toggle-status'
export const applicationAllocationDestroy = 'seeds/seeds-manage/seeds-allocation-app/destroy'
export const varietyList = 'seeds/seeds-manage/seeds-allocation-app/varietyList'
export const companyFarmerInfoList = 'seeds/config/company-farmers/listAll'
export const dealerList = 'fertilizer/config/dealer-setup/list-all'
export const dealerInfoRegNoWise = '/fertilizer/config/dealer-setup/dealer-list/'
export const dealerWiseAllocationStore = '/seeds/seeds-manage/seeds-allocation/store'
export const dealerNameList = '/seeds/seeds-manage/seeds-allocation/dealerName'
export const dealerWiseAllocationList = '/seeds/seeds-manage/seeds-allocation/dealer-wise-list'
export const dealerWiseDetailsList = '/seeds/seeds-manage/seeds-allocation/show'
export const nameWiseRegistrationList = '/seeds/seeds-manage/seeds-allocation/registrtion'
export const varietiesPriceWise = '/seeds/config/price-infos/varieties-price-wise'
// allocation-program api
export const officeWiseAllocationList = 'seeds/seeds-manage/seeds-allocation/office-wise-list'
export const officeWiseStocksAllocationList = 'seeds/seeds-manage/seeds-allocation/spc-office-stocks'
export const officeWiseAllocationStore = 'seeds/seeds-manage/seeds-allocation/store'
export const officeWiseAllocationUpdate = 'seeds/seeds-manage/seeds-allocation/update'
export const officeWiseAllocationEdit = 'seeds/seeds-manage/seeds-allocation/edit'
export const officeWiseAllocationToggleStatus = 'seeds/seeds-manage/seeds-allocation/toggle-status'
export const officeWiseAllocationDestroy = 'seeds/seeds-manage/seeds-allocation/destroy'
// disburse-program api
export const officeWiseDisburseList = 'seeds/seeds-manage/seeds-disburse/office-wise-list'
export const officeCropWiseAllocation = 'seeds/seeds-manage/seeds-allocation/office-crop-wise-allocation'
export const officeWiseStocksDisburseList = 'seeds/seeds-manage/seeds-disburse/spc-office-stocks'
export const officeWiseDisburseStore = 'seeds/seeds-manage/seeds-disburse/store'
export const officeWiseDisburseUpdate = 'seeds/seeds-manage/seeds-disburse/update'
export const officeWiseDisburseEdit = 'seeds/seeds-manage/seeds-disburse/edit'
export const officeWiseDisburseToggleStatus = 'seeds/seeds-manage/seeds-disburse/toggle-status'
export const officeWiseDisburseDestroy = 'seeds/seeds-manage/seeds-disburse/destroy'
export const availableVarietyPrice = 'seeds/seeds-manage/seeds-disburse/available-variety-with-price'
export const CompanyDealerFarmerWiseDisburse = 'seeds/seeds-manage/seeds-disburse/company-dealer-farmer-wise-disburse'
// Company Farmer Disburse
export const companyFarmerDisburseStore = 'seeds/seeds-manage/company-farmer-disburse/store'
export const companyFarmerDisburseList = 'seeds/seeds-manage/company-farmer-disburse/list'
export const comFarDisburseVarietyUnitPrice = 'seeds/seeds-manage/company-farmer-disburse/variety-unit-price'
export const comFarDisburseVarietyAvailableQty = 'seeds/seeds-manage/company-farmer-disburse/variety-available-quantity'
// Dealer Disburse
export const dealerDisburseStore = 'seeds/seeds-manage/dealer-disburse/store'
export const dealerDisburseList = 'seeds/seeds-manage/dealer-disburse/list'
export const dealerDisburseQty = 'seeds/seeds-manage/dealer-disburse/variety-dealer-available-quantity'
export const dealerReceiveQty = 'seeds/seeds-manage/dealer-disburse/variety-dealer-receive-quantity'
export const delarWiseUnitPrice = 'seeds/seeds-manage/dealer-disburse/variety-dealer-unit-price'
// Seed Dashboard Api Routes....
export const seedMainDashboard = 'seeds/seed-dashboard/dashboard'
