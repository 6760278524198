<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
          <iq-card>
              <template>
                  <b-row>
                    <b-col>
                        <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="seedOrgId">
                            {{ $t('seedsSeeds.officeWiseAllocationDetails') }}
                        </list-report-head>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12">
                        <b-row>
                          <b-col lg="2" sm="12">
                            <p class="text-dark">{{ $t('org_pro.organization') }} : </p>
                          </b-col>
                          <b-col lg="7" sm="12">
                            <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.org_name_bn  :  data.org_name  }}</p>
                          </b-col>
                        </b-row>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('warehouse_config.fiscal_year')}} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.fiscal_year_bn  :  data.fiscal_year  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('org_pro_district.district')}}: </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark">{{ ($i18n.locale==='bn') ? data.district_name_bn  :  data.district_name  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{ $t('seedsConfig.seedClass') }} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark capitalize">{{ ($i18n.locale ==='bn') ? data.seed_class_bn  :  data.seed_class  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('seedsConfig.measurementUnit')}} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.measurementUnit_name_bn  :  data.measurementUnit_name  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('seedsConfig.cropType')}} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.crop_type_name_bn  :  data.crop_type_name  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('seedsConfig.cropName')}} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.crop_name_name_bn  :  data.crop_name_name  }}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('org_pro_division.division')}}: </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark">{{ ($i18n.locale==='bn') ? data.division_name_bn  :  data.division_name  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('seedsSeeds.to_spcOfc')}}: </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark">{{ ($i18n.locale==='bn') ? data.zoneOffice_name_bn  :  data.zoneOffice_name  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark capitalize">{{$t('seedsConfig.productionSeason')}} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark">{{ ($i18n.locale==='bn') ? data.production_season_name_bn  :  data.production_season_name  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('seedsConfig.carryover')}} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.carryOver_name_bn  :  data.carryOver_name  }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="12">
                          <p class="text-dark">{{$t('seedsSeeds.allocation_date')}} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark">{{data.allocation_date | dateFormat}}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                    <table class="table table-bordered">
                      <tr>
                        <th style="width:15%">{{$t('globalTrans.sl_no')}}</th>
                        <th style="width:50% font-size:110%"  class="text-center">{{$t('seedsConfig.variety')}}</th>
                        <th class="text-center font-size:110%">{{$t('globalTrans.quantity')}}</th>
                      </tr>
                      <slot v-if="data.details.length>0">
                        <tr :key="index" v-for="(res,index) in data.details">
                          <td>{{index+1}}</td>
                          <td class="text-center">
                            {{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}
                          </td>
                          <td class="text-center">
                            {{$n(res.quantity)}}
                          </td>
                        </tr>
                      </slot>
                      <slot v-else>
                        <tr><td colspan="4"><h4 class="text-danger text-center">{{$t('globalTrans.noDataFound')}}</h4></td></tr>
                      </slot>
                    </table>
              </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { officeWiseAllocationEdit, reportHeadingList } from '../../api/routes'
import ExportPdf from './export_pdf_details'
import ListReportHead from '@/components/custom/ListReportHeadSeed.vue'

export default {
  components: {
      ListReportHead
  },
  props: ['id'],
  created () {
    if (this.id) {
      this.getdataData(this.id)
    }
    this.seedOrgId = 1
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.seedOrgId = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.view'),
      seedOrgId: 0,
      data: {
        id: '',
        allocation_type: 1,
        org_id: 0,
        fiscal_year_id: 0,
        fiscal_year_bn: '',
        fiscal_year: '',
        production_season_id: 0,
        division_id: 0,
        district_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        price_type_id: 0,
        carryovers_id: 0,
        from_spc_office_id: 0,
        to_sales_office_id: 0,
        measurement_unit_id: 0,
        allocation_date: null,
        details: []
      },
      cropNameList: [],
      loader: false,
      search: {
        crop_name_id: 0
      },
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  methods: {
    getdataData (id) {
        RestApi.getData(seedFertilizerServiceBaseUrl, officeWiseAllocationEdit + '/' + id, {}).then(response => {
          this.data = this.getRelationalData(response.data)
        })
    },
    getRelationalData (item) {
      const productionSeasonList = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList
      const cropTypeListList = this.$store.state.SeedsFertilizer.commonObj.CropTypeList
      const cropNameListList = this.$store.state.SeedsFertilizer.commonObj.CropNameList
      const seedClassList = this.$store.state.SeedsFertilizer.commonObj.seedClassList
      const fiscalyearData = this.$store.state.commonObj.fiscalYearList
      const organizationList = this.$store.state.orgList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const spcOfficeList = this.$store.state.SeedsFertilizer.commonObj.spcOfficeList
      const zoneOfficeList = this.$store.state.commonObj.officeList
      const carryoverList = this.$store.state.SeedsFertilizer.commonObj.carryoverList
      const measurementUnitList = this.$store.state.SeedsFertilizer.commonObj.unitList
          const productionSeasonObject = productionSeasonList.find(productionSeason => productionSeason.value === item.production_season_id)
          const cropTypeListObject = cropTypeListList.find(cropTypeList => cropTypeList.value === item.crop_type_id)
          const cropNameListObject = cropNameListList.find(cropNameList => cropNameList.value === item.crop_name_id)
          const fiscalyearObject = fiscalyearData.find(fiscalyear => fiscalyear.value === item.fiscal_year_id)
          const seedClassObject = seedClassList.find(seedClass => seedClass.value === item.seed_class_id)
          const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
          const divisionObject = divisionList.find(division => division.value === item.division_id)
          const districtObject = districtList.find(district => district.value === item.district_id)
          const spcOfficeObject = spcOfficeList.find(spcOffice => spcOffice.value === item.from_spc_office_id)
          const zoneOfficeObject = zoneOfficeList.find(zoneOffice => zoneOffice.value === item.to_sales_office_id)
          const carryOverObject = carryoverList.find(carryover => carryover.value === item.carryovers_id)
          const measurementUnitObject = measurementUnitList.find(measurementUnit => measurementUnit.value === item.measurement_unit_id)
          const productionSeasonData = {
            production_season_name: productionSeasonObject ? productionSeasonObject.text_en : '',
            production_season_name_bn: productionSeasonObject ? productionSeasonObject.text_bn : ''
          }
          const cropTypeListData = {
            crop_type_name: cropTypeListObject ? cropTypeListObject.text_en : '',
            crop_type_name_bn: cropTypeListObject ? cropTypeListObject.text_bn : ''
          }
          const cropNameListData = {
            crop_name_name: cropNameListObject ? cropNameListObject.text_en : '',
            crop_name_name_bn: cropNameListObject ? cropNameListObject.text_bn : ''
          }
          const FiscalYearData = {
            fiscal_year: fiscalyearObject ? fiscalyearObject.text_en : '',
            fiscal_year_bn: fiscalyearObject ? fiscalyearObject.text_bn : ''
          }
          const seedClasseData = {
            seed_class: seedClassObject ? seedClassObject.text_en : '',
            seed_class_bn: seedClassObject ? seedClassObject.text_bn : ''
          }
          const orgData = {
            org_name: orgObject ? orgObject.text_en : '',
            org_name_bn: orgObject ? orgObject.text_bn : ''
          }
          const divisionData = {
            division_name: divisionObject ? divisionObject.text_en : '',
            division_name_bn: divisionObject ? divisionObject.text_bn : ''
          }
          const districtData = {
            district_name: districtObject ? districtObject.text_en : '',
            district_name_bn: districtObject ? districtObject.text_bn : ''
          }
          const spcOfficeData = {
            spcOffice_name: spcOfficeObject ? spcOfficeObject.text_en : '',
            spcOffice_name_bn: spcOfficeObject ? spcOfficeObject.text_bn : ''
          }
          const zoneOfficeData = {
            zoneOffice_name: zoneOfficeObject ? zoneOfficeObject.text_en : '',
            zoneOffice_name_bn: zoneOfficeObject ? zoneOfficeObject.text_bn : ''
          }
          const carryOverData = {
            carryOver_name: carryOverObject ? carryOverObject.text_en : '',
            carryOver_name_bn: carryOverObject ? carryOverObject.text_bn : ''
          }
          const measurementUnitData = {
            measurementUnit_name: measurementUnitObject ? measurementUnitObject.text_en : '',
            measurementUnit_name_bn: measurementUnitObject ? measurementUnitObject.text_bn : ''
          }
          return Object.assign({}, item, productionSeasonData, cropTypeListData, cropNameListData, FiscalYearData, seedClasseData, orgData, divisionData, districtData, spcOfficeData, zoneOfficeData, carryOverData, measurementUnitData)
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    pdfExport () {
      const reportTitle = this.$t('seedsSeeds.officeWiseAllocationDetails')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', this.seedOrgId, reportTitle, this.data, this)
    }
  }
}
</script>
