<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loading">
                <b-row>
                  <b-col lg="12" sm="12">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                        <b-row>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Organization" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="data.org_id"
                                      :options="orgList"
                                      id="org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.fiscal_year_id"
                                  :options="fiscalList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Production Season" vid="production_season_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="production_season"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsConfig.productionSeason')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.production_season_id"
                                  :options="productionSeasonList"
                                  id="production_season_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Seed Class" vid="seed_class"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="seed_class"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsConfig.seedClass')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.seed_class_id"
                                  :options="seedClassList"
                                  id="seed_class_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Carryover Id" vid="carryovers_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="carryovers_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsConfig.carryover')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.carryovers_id"
                                  :options="carryoverList"
                                  id="carryovers_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                            <b-col lg="6" sm="12">
                            <ValidationProvider name="Office Type" vid="office_type_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsSeeds.office_type')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.division_id"
                                  :options="divisionList"
                                  id="division_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.district_id"
                                  :options="districtList"
                                  id="district_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Zone Office" vid="to_sales_office_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="to_sales_office_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsSeeds.to_spcOfc')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.to_sales_office_id"
                                  :options="officeList"
                                  id="to_sales_office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Measurement Unit ID" vid="measurement_unit_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="measurement_unit_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsConfig.measurementUnit')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.measurement_unit_id"
                                  :options="measurementUnitList"
                                  id="measurement_unit_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Crop Type" vid="crop_type"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_type"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.crop_type_id"
                                  :options="cropTypeList"
                                  id="crop_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Crop Name" vid="crop_name_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_name_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsConfig.cropName')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.crop_name_id"
                                  :options="cropNameList"
                                  id="crop_name_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @change="getVarietyInfo(data.crop_name_id)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" md="6" sm="12" xs="12">
                            <ValidationProvider name="Allocation Date" vid="allocation_date" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_date"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsSeeds.allocation_date')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                  v-model="data.allocation_date"
                                  placeholder="Select Date"
                                  id="date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                          <div class="col-12"></div>
                          <div class="d-flex justify-content-center" v-if="loader" style="margin: auto;">
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          <table class="table table-bordered" v-else>
                            <tr>
                              <th style="width:15%">{{$t('globalTrans.sl_no')}}</th>
                              <th style="width:50%">{{$t('seedsConfig.variety')}}</th>
                              <th>{{$t('globalTrans.quantity')}}</th>
                            </tr>
                            <slot v-if="data.details.length>0">
                              <tr :key="index" v-for="(res,index) in data.details">
                                <td>{{index+1}}</td>
                                <td>
                                  <slot v-if="res.variety">
                                    {{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}
                                  </slot>
                                  <slot v-else>
                                    {{ ($i18n.locale==='bn') ? res.variety_name_bn  :  res.variety_name }}
                                  </slot>
                                </td>
                                <td>
                                <b-form-input
                                  v-model="res.quantity"
                                  class="mb-2 mr-sm-2 mb-sm-0 form-control"
                                ></b-form-input>
                              </td>
                              </tr>
                            </slot>
                            <slot v-else>
                              <tr><td colspan="4"><h4 class="text-danger text-center">{{$t('globalTrans.noDataFound')}}</h4></td></tr>
                            </slot>
                          </table>
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                          </div>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { officeWiseAllocationStore, officeWiseAllocationUpdate, varietiesNameWise, officeWiseStocksAllocationList } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.data.office_type_id = this.$store.state.dataFilters.officeTypeId
    this.data.org_id = this.$store.state.dataFilters.orgId
    this.data.office_type_id = this.$store.state.dataFilters.officeTypeId
    this.data = Object.assign({}, this.data, { fiscal_year_id: this.currentFiscalYearId })
    if (this.id) {
      const tmp = this.getEditData()
      this.data = tmp
    }
  },
  mounted () {
    flatpickr('#date', {})
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      data: {
        id: '',
        allocation_type: 4,
        org_id: 0,
        fiscal_year_id: 0,
        production_season_id: 0,
        division_id: 0,
        district_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        price_type_id: 0,
        carryovers_id: 0,
        office_type_id: 0,
        to_sales_office_id: this.$store.state.dataFilters.officeId,
        measurement_unit_id: 2,
        allocation_date: null,
        details: []
      },
      cropNameList: [],
      districtList: [],
      officeList: [],
      officeTypeList: [],
      spcOfficeStocks: [],
      varieties: [],
      newData: [],
      loading: false,
      loader: false,
      search: {
        crop_name_id: 0
      }
    }
  },
  watch: {
    'data.crop_type_id': function (newVal, oldVal) {
      this.cropNameList = this.getCropNameList(newVal)
    },
    'data.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'data.org_id': function (newVal, oldVal) {
      this.officeTypeList = this.getOfficeTypeList(newVal)
    },
    'data.district_id': function (newVal, oldVal) {
      this.officeList = this.getOfficeList()
    },
    'data.office_type_id': function (newVal, oldVal) {
     this.officeList = this.getOfficeList()
    }
  },
  computed: {
    orgList: function () {
      const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return tmpData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
        })
    },
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    seedClassList: function () {
       return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
    },
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    carryoverList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.carryoverList.filter(item => item.status === 1)
    },
    measurementUnitList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.unitList
    },
    currentFiscalYearId: function () {
      return this.$store.state.SeedsFertilizer.currentFiscalYearId
    }
  },
  methods: {
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    // getSpcOfficeList (districtId = null) {
    //   const spcOfficeList = this.$store.state.SeedsFertilizer.commonObj.spcOfficeList.filter(item => item.status === 1)
    //   if (districtId) {
    //     return spcOfficeList.filter(spcOffice => spcOffice.district_id === districtId)
    //   }
    //   return spcOfficeList
    // },
    // getZoneOfficeList (districtId = null) {
    //   const zoneOfficeList = this.$store.state.SeedsFertilizer.commonObj.zoneOfficeList.filter(item => item.status === 1)
    //   if (districtId) {
    //     return zoneOfficeList.filter(zoneOffice => zoneOffice.district_id === districtId)
    //   }
    //   return zoneOfficeList
    // },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.data.off_dealer_farmer_comp_id = this.data.from_spc_office_id
      if (this.data.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${officeWiseAllocationUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, officeWiseAllocationStore, this.data)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
        this.loading = false
      }
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    // async getVarietyInfo (cropNameId) {
    //   this.loader = true
    //   this.search.crop_name_id = cropNameId
    //   RestApi.getData(seedFertilizerServiceBaseUrl, varietiesNameWise, this.search).then(response => {
    //     this.varieties = response.data
    //     this.loader = false
    //   })
    // },
     getVarietyInfo (cropNameId) {
      this.loader = true
      this.search.crop_name_id = cropNameId
      RestApi.getData(seedFertilizerServiceBaseUrl, varietiesNameWise, this.search).then(response => {
        this.data.details = response.data.map(item => {
          const quantityData = {
              quantity: ''
          }
          return Object.assign({}, item, quantityData)
        })
        this.loader = false
      })
    },
    async geCheckSpcOfficeVariety () {
      const newData = []
      this.varieties.map(item => {
        if (this.spcOfficeStocks.length > 0) {
          this.spcOfficeStocks.forEach((element, key) => {
            if (element.variety_id === item.variety_id) {
              item.max = element.stock_quantity
              item.quantity = 0
              newData.push(item)
            }
          })
        }
      })
      this.data.details = newData
    },
    maxQtyCheck: function (item, event) {
      item.old_quantity = item.quantity
      item.quantity = event.target.value
      if (item.quantity > item.max) {
        item.quantity = item.old_quantity
        this.$swal({
          title: this.$t('seedsSeeds.qty_more_than_stock')
        }).then((resultSwal) => {
        })
      }
    },
    geSpcOfficeStocksInfo (spcOfficeId, type = null) {
      if (this.data.fiscal_year_id && spcOfficeId) {
        RestApi.getData(seedFertilizerServiceBaseUrl, officeWiseStocksAllocationList + '?spc_office_id=' + encodeURI(spcOfficeId) + '&fiscal_year_id=' + encodeURI(this.data.fiscal_year_id) + '&production_season_id=' + encodeURI(this.data.production_season_id) + '&carryovers_id=' + encodeURI(this.data.carryovers_id) + '&seed_class_id=' + encodeURI(this.data.seed_class_id) + '&org_id=' + encodeURI(this.data.org_id) + '&crop_type_id=' + encodeURI(this.data.crop_type_id) + '&crop_name_id=' + encodeURI(this.data.crop_name_id), {}).then(response => {
        if (response.success) {
          this.spcOfficeStocks = response.data
          if (type === 'not-edit') {
            this.geCheckSpcOfficeVariety()
          }
        }
      })
      }
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
            return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
      return officeTypeList
    },
    getOfficeList () {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (this.data.office_type_id) {
        return officeList.filter(item => item.office_type_id === this.data.office_type_id)
      }
      if (this.data.district_id) {
        return officeList.filter(item => item.district_id === this.data.district_id)
      }
      return officeList
    }
  }
}
</script>
